import React from "react";
import { Box, Text, Heading, PseudoBox, IconButton } from "@chakra-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

export default () => {
  const data = useStaticQuery(graphql`
    {
      pageContent: wpgraphql {
        collaborators {
          nodes {
            id
            title
            customFields {
              description
              email
              website
              picture {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fixed(width: 600) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const people = data.pageContent.collaborators.nodes
    .map((el) => ({
      id: el.id,
      name: el.title,
      description: el.customFields && el.customFields.description,
      website: el.customFields.website,
      email: el.customFields.email,
      image: el.customFields && el.customFields.picture,
      img:
        el.customFields &&
        el.customFields.picture &&
        el.customFields.picture.sourceUrl,
    }))
    .reverse();
  return (
    <Box className="row-wrapper" px={2} mb={4}>
      <Box maxW="3xl" m="auto">
        <Box
          display={["block", "grid"]}
          gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          gridColumnGap="4%"
          my={2}
          pb={4}
        >
          {people
            ? people.map((person) => (
                <PseudoBox
                  key={person.id}
                  rounded="lg"
                  overflow="hidden"
                  borderWidth="1px"
                  position="relative"
                  mb={[4, 2]}
                  textAlign="center"
                  maxWidth="300px"
                >
                  <GatsbyImage
                    fixed={person.image.imageFile.childImageSharp.fixed}
                    style={{
                      height: "232px",
                      width: "100%",
                      maxWidth: "300px",
                      marginBottom: "1.5rem",
                      backgroundColor: "#eee",
                    }}
                  />

                  <Box p={4}>
                    <Heading
                      as="h4"
                      my={2}
                      fontSize="lg"
                      fontWeight="400"
                      color="gray.600"
                      lineHeight="1"
                    >
                      {person.name}
                    </Heading>
                    <Text fontSize="sm" color="gray.500" pb={2}>
                      {person.description}
                    </Text>
                  </Box>
                  <Box
                    textAlign="center"
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                  >
                    {person.email && (
                      <a href={`mailto:${person.email}`}>
                        <IconButton
                          variant="ghost"
                          color="gray.400"
                          aria-label="Send email"
                          icon="email"
                        />
                      </a>
                    )}
                    {person.website && (
                      <a
                        href={person.website}
                        className="href"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconButton
                          variant="ghost"
                          color="gray.400"
                          aria-label="Connect"
                          icon="external-link"
                        />
                      </a>
                    )}
                  </Box>
                </PseudoBox>
              ))
            : null}
        </Box>
      </Box>
    </Box>
  );
};
