import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../gatsby-plugin-based-on-staticfuse/components/Layout";
import SEO from "gatsby-plugin-based-on-staticfuse/src/components/SEO";
import CollaboratorsSection from "../../gatsby-plugin-based-on-staticfuse/components/CollaboratorsSection";
import { Box, Heading } from "@chakra-ui/core";

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      pageContent: wpgraphql {
        text: pageBy(pageId: 138) {
          slug
          content
        }
      }
    }
  `);

  return (
    <Layout location={{ location }} fullWidth="true">
      <SEO title="About" />
      <Box maxW="3xl" m="auto" py={4} my={4} px={2}>
        <Heading as="h1" fontWeight="600" size="xl" lineHeight="1.1" my={8}>
          About us
        </Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: data.pageContent.text.content,
          }}
        ></div>
      </Box>
      <CollaboratorsSection />
    </Layout>
  );
};

export default About;
